<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5 6.5C9.93284 6.5 9.31032 6.78 8.79767 7.45038C8.66735 7.62081 8.55299 7.80515 8.4546 8H10.25C10.6642 8 11 8.33579 11 8.75C11 9.16421 10.6642 9.5 10.25 9.5H8.02592C7.99136 9.83152 7.99136 10.1685 8.02592 10.5H10.25C10.6642 10.5 11 10.8358 11 11.25C11 11.6642 10.6642 12 10.25 12H8.4546C8.55299 12.1948 8.66735 12.3792 8.79767 12.5496C9.31032 13.22 9.93284 13.5 10.5 13.5C11.0672 13.5 11.6897 13.22 12.2023 12.5496C12.4539 12.2206 12.9247 12.1578 13.2537 12.4094C13.5827 12.661 13.6455 13.1318 13.3939 13.4608C12.6373 14.4501 11.5963 15 10.5 15C9.40366 15 8.36269 14.4501 7.60613 13.4608C7.27168 13.0234 7.01308 12.5277 6.83031 12H5.75C5.33579 12 5 11.6642 5 11.25C5 10.8358 5.33579 10.5 5.75 10.5H6.51977C6.49341 10.1676 6.49341 9.83243 6.51977 9.5H5.75C5.33579 9.5 5 9.16421 5 8.75C5 8.33579 5.33579 8 5.75 8H6.83031C7.01308 7.47233 7.27168 6.97657 7.60613 6.53921C8.36269 5.54986 9.40366 5 10.5 5C11.5963 5 12.6373 5.54986 13.3939 6.53921C13.6455 6.86824 13.5827 7.33895 13.2537 7.59056C12.9247 7.84218 12.4539 7.77942 12.2023 7.45038C11.6897 6.78 11.0672 6.5 10.5 6.5Z"
      fill="currentColor"
    />
  </svg>
</template>
